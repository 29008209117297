<template>
  <v-container id="category-table" fluid tag="section">
    <base-material-card
      icon="mdi-currency-usd"
      inline
      color="secondary"
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{ $t("vehicle_title") }}</b>
        </h1></template
      >
      <v-btn
        data-test="Vehicle:Vehicle:BtnAddNewVehicle"
        color="primary"
        rounded
        @click="addVehicle"
        absolute
        fab
        top
        right
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
      <v-form
        class="form-filter"
        @submit.stop.prevent="submitFilter()"
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
      >
        <v-row justify="center" class="mt-6">
          <v-text-field
            class="mx-3"
            dense
            outlined
            :label="$t('search')"
            v-model="nameFilter"
            append-icon="mdi-close"
            @click:append="handleCleanFilter"
          ></v-text-field>
          <v-btn
            :disabled="filterLoading"
            class="mx-3"
            color="secondary"
            type="submit"
            >{{ $t("apply") }}</v-btn
          >
        </v-row>
      </v-form>
      <div>
        <div v-if="!allLoaded">
          <div v-if="cleanVehicleData && cleanVehicleData.length > 0">
            <v-simple-table>
              <thead>
                <tr>
                  <th>{{ $t("name") }}</th>
                  <th>{{ $t("company_id") }}</th>
                  <th>{{ $t("nire") }}</th>
                  <th class="text-center">{{ $t("edit") }}</th>
                  <th class="text-center">{{ $t("remove") }}</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(vehicle, index) in cleanVehicleData"
                  :key="index"
                  data-test="Vehicle:Vehicle:Teste"
                >
                  <td>{{ vehicle.name }}</td>
                  <td>{{ vehicle.cnpj ? vehicle.cnpj : "-" }}</td>
                  <td>{{ vehicle.nire ? vehicle.nire : "-" }}</td>
                  <td class="text-center">
                    <v-btn
                      :data-test="`Vehicle:Vehicle:BtnEdit:${vehicle.name}`"
                      @click="ToEditVehicle(vehicle)"
                      class="px-2 ml-1 secondary"
                      min-width="0"
                      small
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td class="text-center">
                    <v-tooltip
                      bottom
                      color="gray"
                      :disabled="vehicle.canBeDeleted"
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            :disabled="!vehicle.canBeDeleted"
                            :data-test="`Vehicle:Vehicle:BtnDelete:${vehicle.name}`"
                            @click="open_delete_dialog(vehicle)"
                            class="px-2 ml-1"
                            color="red"
                            min-width="0"
                            small
                          >
                            <v-icon small>mdi-trash-can</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>{{ $t("can_be_deleted_vehicle") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-pagination
              color="primary"
              @input="changePage"
              v-model="page"
              :length="pageCount"
            >
            </v-pagination>
          </div>
          <div v-else>
            <p class="not-found-items">{{ $t("not_found_filter") }}</p>
          </div>
        </div>
        <v-progress-circular
          v-else
          style="margin-left: 50%; margin-top: 32px; margin-bottom: 32px"
          indeterminate
          size="70"
          color="primary"
        ></v-progress-circular>
      </div>
    </base-material-card>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.name"
        @close="delete_dialog = false"
        @delete="delete_vehicle"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
</template>

<script>
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal.vue";
import ApiService from "@/services/ApiService";
import { formatCnpj } from "@/shared/helpers/cnpjHelper.js";

export default {
  name: "Vehicle",
  components: {
    DeleteConfirmationModal,
  },
  data() {
    return {
      apiService: new ApiService(),
      vehiclesData: null,
      allLoaded: false,
      dialog: false,
      editVehicle: null,
      delete_dialog: false,
      delete_obj: null,
      cleanVehicleData: null,
      page: 1,
      pageCount: 1,
      nameFilter: "",
      cleaner: false,
      valid: true,
      filterLoading: false,
      lazy: false,
    };
  },
  watch: {
    vehiclesData() {
      const newVehiclesData = [...this.vehiclesData];
      const cleanNewVehiclesData = newVehiclesData.map((vehicle) => {
        let cleanCnpj = formatCnpj(vehicle.cnpj);
        let cleanNire = vehicle.nire;

        if (!cleanNire) {
          cleanNire = "-";
        }

        return {
          id: vehicle.id,
          name: vehicle.name,
          cnpj: cleanCnpj,
          nire: cleanNire,
          canBeDeleted: vehicle.canBeDeleted,
        };
      });

      this.cleanVehicleData = cleanNewVehiclesData;
    },
  },
  created() {
    this.getVehicles();
  },
  methods: {
    async getVehicles() {
      this.filterLoading = true;
      this.allLoaded = true;
      let currentPage = this.page;

      let params = { Page: currentPage };

      if (this.nameFilter && this.nameFilter.length) {
        params.nameFilter = this.nameFilter;
      }

      await this.apiService
        .postRequest("investmentvehicle/list/filter", params)
        .then((result) => {
          const totalItemsPorPage = result.content.perPage;

          this.page = result.content.currentPage;
          this.pageCount = Math.ceil(result.content.total / totalItemsPorPage);
          this.vehiclesData = result.content.items;
          if (params.nameFilter) {
            this.cleaner = true;
          }
        })
        .catch((err) => {
          this.$toast.error("error_occoured");
        });

      this.allLoaded = false;
      this.filterLoading = false;
    },
    async changePage() {
      await this.getVehicles();
    },
    open_delete_dialog: function (vehicle) {
      this.delete_dialog = true;
      this.delete_obj = vehicle;
    },
    delete_vehicle: async function (vehicle) {
      this.delete_dialog = false;
      await this.apiService
        .deleteRequest(`investmentvehicle/${vehicle.id}`)
        .then((resp) => {
          this.getVehicles();
          this.$toast.success(this.$t("saved"));
        })
        .catch((error) => {
          this.$toast.error(this.$t("not_allowed_delete_vehicle"));
          return error;
        });
    },
    addVehicle() {
      this.$router.push({ path: "/vehicle/add" });
    },
    ToEditVehicle(vehicle) {
      this.$router.push({ path: `/vehicle/edit/${vehicle.id}` });
    },
    async submitFilter() {
      if (this.nameFilter && this.nameFilter.length) {
        this.page = 1;
        await this.getVehicles();
      }
    },
    async handleCleanFilter() {
      this.nameFilter = null;
      if (this.cleaner) {
        this.cleaner = false;
        this.page = 1;
        await this.getVehicles();
      }
    },
  },
};
</script>

<style scoped>
.form-filter {
  max-width: 50%;
}

.not-found-items {
  text-align: center;
  margin-top: 32px;
  font-size: 16px;
  font-weight: 500;
}
</style>
